import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Container, Search, Collapses } from "./styles";
import { GoBack } from '../../components/GoBack';
import { FiSearch } from "react-icons/fi";
import { Hide } from "../../components/Hide";
import { replaceAllAcemptuation } from "../../data/formatting/latin-replace-acemptuation/latin-replace-acemptuation";
import { useEffect, useState, useRef } from "react";
import api from "../../services/api";
import { NoMatchError } from "../../components/NoMatchError";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { ModalDefault } from "../../components/ModalDefault";

export function Recources() {
    const [resources, setResources] = useState([])
    // console.log("🐌🧲 ~ file: index.jsx ~ line 11 ~ HelpLibraries ~ libraries", libraries)
    const [search, setSearch] = useState('')
    const [modalWarning, setModalWarning] = useState(false);
    const [modalText, setModalText] = useState('');

    const focus = useRef()

    const fetchForLibraries = async () => {
        try {
            const response = await api.get(`/accessibility-features`)

            if (response) {
                if (response.status === 200) {
                    setResources(response.data.filter(({inactive}) => !inactive))
                    return
                }
            }
        } catch (error) {
            console.log(error, 'error while fetching for resources')
        }
    }

    useEffect(() => {
        fetchForLibraries()
    }, [])

    useEffect(() => {
        focus.current?.focus();
    }, [])

    const handleDownloadClick = (url, textModal) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setModalText(textModal);
        setModalWarning(true);
    };

    let filteredStateByNameOrCity = resources

    if (search.length > 0) {
        const workedSearchString = replaceAllAcemptuation(search)
        filteredStateByNameOrCity = resources.filter(({ title, }) => replaceAllAcemptuation(title).search(workedSearchString) > -1)
    }
    return (
        <>
            <ModalDefault
                type="error"
                title="Atenção!"
                isOpen={modalWarning}
                onRequestClose={() => setModalWarning(false)}
                showCloseButton
            >
                <br/><p>{modalText}</p><br/>
            </ModalDefault>
            <Header menu={5} />
            <GoBack title="Recursos de acessibilidade" />
            <Container>
                <Search>
                    <div>
                        <input ref={focus} value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Buscar recursos" />
                        <button tabIndex={-1} aria-hidden={true}> <FiSearch /> </button>
                    </div>
                </Search>
                <Collapses>
                    {
                        filteredStateByNameOrCity.map(({ title, description, url, textModal }, key) => <Hide
                            title={title}
                            key={key + 'resources'}>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
                            <button
                                onClick={() => handleDownloadClick(url, textModal)}
                                style={{
                                    backgroundColor: '#E9B00D',
                                    color: '#000000',
                                    border: 'none',
                                    padding: '10px',
                                    fontWeight: 'bold',
                                    width: '65px',
                                    cursor: 'pointer',
                                    borderRadius: '13px',
                                    marginTop: '10px'
                                }}
                                aria-label={`Baixar recurso ${title}`}
                            >
                                Baixar
                            </button>
                        </Hide>)
                    }
                    {
                        filteredStateByNameOrCity.length === 0 ?
                            <NoMatchError />
                            : null
                    }
                </Collapses>
            </Container>
            <Footer />
        </>
    )
}