import styled from 'styled-components';

export const Container = styled.div`
  width: var(--width-pp);
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
  @media screen and (max-width: 720px) {
    width: 100vw;
    margin: 0 32px;
  }
  
  h2 {
    margin: 10px 0;
  }

  p {
    margin: 4px 0;
  }

  a {
    text-decoration: underline !important;
  }

  button {
    background-color: #e9b00d;
    padding: 10px;
    border: none;
    border-radius: 13px;
    font-weight: bold;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    padding: 0 32px;
    margin: 0;
  }
  @media screen and (max-width: 1200px) and (min-width: 721px) {
    width: 100%;
    padding: 0 32px;
    margin: 0;
  }
`;
