import React, {useEffect} from 'react';
import {Header} from '../../components/Header';
import {GoBack} from '../../components/GoBack';
import {Footer} from '../../components/Footer';
import {Container} from './styles';

export function Ddreader() {
    useEffect(() => {
        download();
    }, []);
    const download = () => {
        const url = "https://ddreader-download.s3.us-east-1.amazonaws.com/DDReader%2B.exe";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ddreader");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const handleDownload = () => {
        return download();
    }
    return (
        <>
            <Header/>
            <GoBack ariaLabel={'Voltar para página anterior'} title="Download DDReader"/>
            <Container aria-label="Download DDReader" tabIndex={0}>
                <p>O download será iniciado em breve. Caso não ocorra use o botão abaixo.</p>
                <button onClick={handleDownload}>Baixar</button>
            </Container>
            <Footer/>
        </>
    );
}
